.container {
  display: inline-block;
  max-width: 42rem;
  text-align: center;
  padding-bottom: 1.6rem;
}

.errorImage {
  display: block;
  height: 12rem;
  margin: 1rem auto;
}

.title {
  margin: 2rem 0 1rem 0;
}

.content {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.container a {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  margin: 0rem auto 2rem auto;
}

@media screen and (max-width: 44rem) {
  .errorImage {
    height: 6rem;
  }
  .title {
    font-size: 15pt;
  }
  .content {
    font-size: 11pt;
    padding: 0 0rem;
  }
}
