.container {
  display: block;
  max-width: 26rem;
  padding: 0 2rem;
  text-align: start;
  transition: all 0.35s ease-in-out;
}

.title {
  margin: 1rem 0 1rem 0;
  text-align: center;
}

.content {
  text-align: center;
}

.legal{
  display: flex;
  align-items: flex-start;
  margin:  1rem 0 1.5rem 0;

}

.legal p{
 margin: 0;
 font-size: 10pt;
}

.link{
  color: #001e3c;
  font-weight: 700;
  text-decoration: none;
}

.button{
  display: block;
  margin: 0 auto;
  text-align: center;
}

.additional{
  margin-top: 2rem;
  text-align: center;
}

.success{ 
  text-align: center;
}

@media screen and (max-width: 44rem) {
  .title {
    font-size: 15pt;
  }
  .container {
    padding: 0;
  }
}
