.container {
  display: block;
  max-width: 22rem;
  padding: 0 2rem;
  text-align: center;
  transition: all 0.35s ease-in-out;
}

.form {
  display: block;
  transition: all 0.35s ease-in-out;
}

.title {
  margin: 2rem 0 1rem 0;
}

.content {
  margin-bottom: 2rem;
}

.button {
  text-align: center;
  display: block;
  margin-top: 1rem;
}

.additional {
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.additional a {
  display: block;
}

.link {
  color: #001e3c;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (max-width: 44rem) {
  .container {
    padding: 0 0rem;
  }
}
