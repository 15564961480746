.footer {
  margin: 2rem 0 3rem 0;
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 10pt;
}
.footer span {
  margin-right: 0.6rem;
}

.footer a {
  text-decoration: none;
  cursor: pointer;
  color: #888888;
}

.footer a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 580px) {
  .footer {
    flex-direction: column;
  }
  .footer span {
    margin-right: 0;
    margin-bottom: 0.6rem;
  }
}
