.card {
  background-color: #fff;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
}

.content {
  padding: 1.5rem;
}

.bottomBar {
  padding: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.infoStatus {
  margin: 0;
}

.errorStatus {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #e63946;
}

.errorStatus p {
  color: #e63946;
  margin: 0;
}

/* Settings */
.settingSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  gap: 0.5rem;
}

.settingTextfield {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1.5;
  gap: 0.5rem;
}

.settingInfos h2 {
  margin: 0;
  font-size: 13pt;
  font-weight: 700;
}

.settingInfos p {
  font-size: 11pt;
  margin: 0;
}

.settingsBanner {
  background-color: rgba(0, 30, 60, 0.075);
  padding: 1rem 1rem;
  border-radius: 0.33rem;
  color: rgba(0, 30, 60, 1);
  max-width: 30rem;
  margin-top: 1rem;
  align-items: center;
  display: flex;
  gap: 0.75rem;
}
.settingsBanner p {
  font-size: 11pt;
  font-weight: 600;
  color: rgba(0, 30, 60, 1);
}

.settingsBanner a {
  color: rgba(0, 30, 60, 1);
}

.settingTheme {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.themeSelectorContainer {
  position: relative;
  border-radius: 0.33rem;
  height: 8rem;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.themeSelectorModal {
  width: 30%;
  height: 60%;
  border-radius: 0.33rem;
  display: grid;
  gap: 10%;
  padding: 5%;
  grid-template-rows: 4fr 1fr;
}

.themeSelectorButton {
  width: 100%;
  height: 100%;
  border-radius: 0.33rem;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.themeSelectorButtonText {
  background-color: #fff;
  height: 20%;
  width: 50%;
  border-radius: 0.33rem;
}

.themeSelectorTextContainer {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 5%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.themeSelectorTitle {
  height: 15%;
  border-radius: 0.33rem;
  width: 50%;
  margin-bottom: 5%;
}

.themeSelectorText:last-child {
  width: 70%;
}

.themeSelectorText {
  height: 10%;
  width: 100%;
  border-radius: 0.33rem;
}

.themeSelectorCheck {
  position: absolute;
  top: 5%;
  left: 5%;
}

.settingImage {
  background-color: #f2f6fb;
  height: 8rem;
  min-width: 19%;
  border-radius: 0.33rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.settingImageHero {
    background-color: #f2f6fb;
    height: 8rem;
    min-width: 19%;
    border-radius: 0.33rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
@media screen and (max-width: 44rem) {
  .settingTextfield {
    align-items: stretch;
    flex-direction: column;
    gap: 1rem;
  }
  .settingTheme {
    width: 100%;
  }
}
