.colorPickerButton {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.33rem;
}

.colorPickerButton:hover {
  cursor: pointer;
}

.colorPopUp {
  padding: 1rem;
}

