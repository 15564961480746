.container {
  display: block;
  max-width: 25rem;
  padding: 0 2rem;
  text-align: center;
  transition: all 0.35s ease-in-out;
}

.stepper {
  background-color: #001e3c;

  display: block;
  width: 7.5rem;
  margin-top: 1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.33rem;
  font-size: 10pt;
}

.stepperContent {
  margin: 0;
  color: #dddddd;
  display: inline-block;
}

.title {
  margin: 2rem 0 0rem 0;
}

.content {
  margin: 0 0 2rem 0;
}

.form {
  display: block;
  transition: all 0.35s ease-in-out;
}

.button {
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.5rem;
  margin: 2rem 0 1rem 0;
}

.formSameRow {
  display: flex;
  justify-content: stretch;
  gap: 1rem;
}

.legal {
  display: flex;
  align-items: flex-start;
  margin: 1rem 0 1.5rem 0;
}

.legal p {
  margin: 0;
  font-size: 10pt;
  text-align: start;
}

.link {
  color: #001e3c;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (max-width: 44rem) {
  .container {
    padding: 0 0rem;
  }
}
