.container {
  display: block;
  max-width: 28rem;
  text-align: start;
  transition: all 0.35s ease-in-out;
}

.title {
  margin: 1rem 0 1rem 0;
  text-align: center;
}

.progress {
  display: block;
  margin: 3rem auto;
  text-align: center;
}

.info {
  text-align: center;
}

@media screen and (max-width: 44rem) {
  .title {
    font-size: 15pt;
  }
}
