.logo {
  height: 2rem;
  margin: 6rem auto 2rem auto;
  display: block;
  cursor: pointer;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  background-color: #001e3c;
  border-radius: 0.33rem 0.33rem 0 0;
  padding: 1rem;
  min-width: 18rem;
  max-width: 26rem;
  text-align: center;
  color: #ffffff;
}

.banner p {
  margin: 0;
  color: #ffffff;
}

.banner a {
  margin: 0;
  color: #ffffff;
  text-decoration: underline;
}

.bannerCard {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 0.33rem;
  min-height: 12rem;
  min-width: 18rem;
  max-width: 86rem;
  padding: 0 0 1rem 0;
  margin: 0 1rem 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.bannerPadding {
  padding: 0 1rem 0 1rem;
}

.card {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 0.33rem;
  min-height: 12rem;
  min-width: 18rem;
  max-width: 86rem;
  padding: 1rem;
  margin: 0 1rem 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

@media screen and (max-width: 580px) {
  .logo {
    margin: 3rem auto 2rem auto;
  }
}
