.body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/*
* Banner
*/

.banner {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #001e3c;
  padding: 1rem;
  text-align: center;
  color: #ffffff;
}

.banner p {
  flex-grow: 1;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.banner a {
  margin: 0;
  color: #ffffff;
  text-decoration: underline;
}

/*
* Dashboard
*/

.grid {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 16rem auto;
  grid-template-rows: 5rem 1fr;
  overflow: hidden;
}

/*
* Drawer
*/

.navDesktop {
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  grid-row-start: 1;
  grid-row-end: 3;
  overflow: auto;
}

.nav {
  padding: 1.5rem;
}

.navLogo {
  height: 2rem;
  padding: 0 0.5rem 1rem 0.5rem;
  display: block;
  cursor: pointer;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #7e7e7e;
  text-decoration: none;
  margin: 1rem 0;
  padding: 0.35rem 0.5rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.navItemSelected {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  text-decoration: none;
  margin: 1rem 0;
  padding: 0.35rem 0.5rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.navItemSelected .navItemIcon {
  color: #ffffff;
  background-color: #001e3c;
  padding: 0.35rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.navItem:hover {
  color: #001e3c;
  cursor: pointer;
  background-color: rgba(0, 30, 60, 0.1);
}
.navItem:hover p {
  color: #001e3c;
}

.navItemIcon {
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.35rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.navItem:hover .navItemIcon {
  cursor: pointer;
  color: #ffffff;
  background-color: #001e3c;
}

.navItem p {
  margin: 0;
  color: #747474;
  font-weight: 400;
  user-select: none;
  transition: all 0.25s ease-in-out;
}
.navItemSelected p {
  margin: 0;
  color: #001e3c;
  font-weight: 600;
  user-select: none;
}

/*
* Toolbar
*/

.toolbar {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.04);
}

.toolbarHamburger {
  display: none;
  align-items: center;
}

.toolbarHamburgerIcon {
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.74rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.toolbarHamburgerIcon:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #001e3c;
}

.toolbarLogo {
  height: 2rem;
  display: none;
  cursor: pointer;
}

.toolbarOrganisation {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.33rem;
}

.toolbarOrganisation:hover {
  cursor: pointer;
  background-color: rgba(0, 30, 60, 0.1);
  transition: all 0.25s ease-in-out;
}

.toolbarOrganisation:hover .toolbarOrganisationIcon {
  color: #ffffff;
  background-color: #001e3c;
  transition: all 0.25s ease-in-out;
}

.toolbarOrganisationIcon {
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.74rem;
  border-radius: 0.33rem;
}

.toolbarOrganisation p {
  margin: 0;
  font-weight: bolder;
  color: #001e3c;
  user-select: none;
}

.organisationContextMenu {
  background-color: #fff;
  padding: 0.75rem;
  border-radius: 0.33rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.04);
}
.organisationContextMenuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.33rem;
  padding: 0.5rem 1rem;
}

.organisationContextMenuItem p {
  margin: 0.5rem 0;
  font-weight: 600;
  color: #001e3c;
}

.organisationContextMenuItem:hover {
  cursor: pointer;
  background-color: rgba(0, 30, 60, 0.1);
  transition: all 0.25s ease-in-out;
}

.organisationContextMenuItem:hover .organisationContextMenuItemIcon {
  color: #ffffff;
  background-color: #001e3c;
}
.organisationContextMenuItemIcon {
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.74rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.organisationContextMenuItemIconLogout {
  color: #d32f2f;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.74rem;
  border-radius: 0.33rem;
}

.organisationContextMenuItem:hover .organisationContextMenuItemIconLogout {
  color: #ffffff;
  background-color: #d32f2f;
  transition: all 0.25s ease-in-out;
}

.organisationContextMenuItemDivider {
  height: 1px;
  margin: 0.35rem 0;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 0.33rem;
}

@media screen and (max-width: 60rem) {
  .toolbarHamburger {
    display: flex;
  }

  .toolbarLogo {
    display: block;
  }

  .toolbarOrganisation {
    padding: 0;
  }

  .toolbarOrganisation p {
    display: none;
  }

  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 5rem 1fr;
  }
  .navDesktop {
    display: none;
  }

  .body {
    height: fit-content;
    min-height: 100vh;
  }

}

/*
* Content
*/

.main {
  overflow: auto;
}

.content {
  min-height: 90vh;
}
