body {
  background-color: #f2f6fb;
  margin: 0;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2c2c;
  display: block;
}

p {
  color: #555555;
  display: block;
}

@media screen and (max-width: 44rem) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15pt;
  }
  p {
    font-size: 11pt;
  }
}
