.main {
  display: grid;
  gap: 1.5rem;
}

.purchasesStats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.purchasesStatsCard {
  background-color: #fff;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  padding: 1rem;
  line-height: 1.5;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.purchasesStatsCardError {
  background-color: #e63946;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  padding: 1rem;
  line-height: 1.5;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.purchasesStatsCard h2 {
  margin: 0;
  color: #777777;
  font-weight: 500;
  font-size: 14pt;
}

.purchasesStatsCard h1 {
  margin: 0;
  font-size: 40pt;
  font-weight: 600;
}

.purchasesStatsCardError h2 {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14pt;
}

.purchasesStatsCardError h1 {
  margin: 0;
  color: #fff;
  font-size: 40pt;
  font-weight: 600;
}

.purchasesList {
  background-color: #fff;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  align-items: center;
  overflow: auto;
}

.purchasesListHeader {
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
  gap: 1rem;
  align-items: center;
  padding: 1rem 1rem;
  min-width: 38rem;
}

.purchasesListHeader h2 {
  margin: 0;
  font-size: 14pt;
}

.purchasesListItem {
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem;
  min-width: 38rem;
}

.purchasesListItem p {
  margin: 0;
  font-size: 12pt;
}

.itemIcon {
  color: #001e3c;
  background-color: rgba(0, 30, 60, 0.1);
  padding: 0.35rem;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
  font-size: 20pt;
}

.listStatusProcessing {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  background-color: rgba(255, 187, 0, 0.1);
  color: rgb(255, 187, 0);
}

.listStatusDone {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  background-color: rgba(37, 206, 0, 0.1);
  color: rgb(37, 206, 4);
}

.listStatusError {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  background-color: rgba(230, 57, 70, 0.1);
  color: rgb(230, 57, 70);
}

.emptyState {
  text-align: center;
}

@media screen and (max-width: 44rem) {
  .purchasesStats {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
