.card {
  background-color: #fff;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  padding: 1.4rem 2rem;
}

.detail h1 {
  margin: 0;
  font-size: 20pt;
  padding-bottom: 0.5rem;
}

.logo {
  max-width: 100%;
  width: 20rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 0.33rem;
}

.statusProcessing {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  display: inline-block;
  background-color: rgba(255, 187, 0, 0.1);
  color: rgb(255, 187, 0);
}

.statusDone {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  display: inline-block;
  border-radius: 0.5rem;
  background-color: rgba(37, 206, 0, 0.1);
  color: rgb(37, 206, 4);
}

.statusError {
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  display: inline-block;
  background-color: rgba(230, 57, 70, 0.1);
  color: rgb(230, 57, 70);
}

.eventInfo {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.eventInfo tr td:first-child {
  width: 20%;
  padding-right: 0rem;
  color: #666;
  vertical-align: baseline;
}

.eventInfo tr td:last-child {
  padding-left: 3rem;
}

.membersList {
  padding: 0 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.membersList h2 {
  font-size: 12pt;
  margin: 0.5rem 0 0.25rem 0;
}

.membersList p {
  margin: 0.25rem 0;
}

.responseivTable {
  overflow: auto;
}
