.container {
  display: block;
  max-width: 26rem;
  padding: 0 2rem;
  text-align: center;
  transition: all 0.35s ease-in-out;
}

.list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: auto;
  max-height: 22rem;
}

.title {
  margin: 1.5rem 0 0rem 0;
}

.content {
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.item {
  border: solid 1px #eeeeee;
  border-radius: 0.33rem;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  line-height: 1.4;
  transition: all 0.15s ease-in-out;
  text-align: start;
}

.item:hover {
  cursor: pointer;
  background-color: #eeeeee;
  color: #ffffff;
}
.itemIcon {
  background-color: #eeeeee;
  border-radius: 0.33rem;
  padding: 1rem;
  fill: #001e3c !important;
  transition: all 0.15s ease-in-out;
}

.item:hover .itemIcon {
  background-color: #001e3c;
  fill: #ffffff !important;
  transition: all 0.15s ease-in-out;
}

.itemContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.itemTitle {
  font-size: 14pt;
  margin: 0;
}

.itemSubTitle {
  margin: 0;
  font-size: 11pt;
  font-weight: 400;
  color: #5f5f5f;
}

.additional {
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.additional a {
  display: block;
}

.link {
  color: #001e3c;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (max-width: 44rem) {
  .container {
    padding: 0 0rem;
  }

  .itemTitle {
    font-size: 12pt;
  }

  .itemSubTitle {
    font-size: 11pt;
  }
}
