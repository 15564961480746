.wrapper {
  padding: 1rem;
  max-width: 71rem;
  margin: 0 auto;
}

.layout {
  padding-top: 1rem;
 display: flex;
 justify-content: space-between;
}

.title{
    font-size: 26pt;
    margin: 0 0 0 0;
    color: #000;
    font-weight: 700;
}