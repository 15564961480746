.main {
  display: grid;
  gap: 1.5rem;
}

.eventList {
  background-color: #fff;
  border-radius: 0.33rem;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  align-items: center;
  overflow: auto;
  margin-top: 0.75rem;
}

.eventListHeader {
  display: grid;
  grid-template-columns: auto 1.5fr 1fr 1fr 1fr 2rem;
  gap: 1rem;
  align-items: center;
  padding: 1rem 1rem;
  min-width: 38rem;
}

.eventListHeader h2 {
  margin: 0;
  font-size: 14pt;
}

.itemIcon {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 0.33rem;
  transition: all 0.25s ease-in-out;
}

.eventListItem {
  display: grid;
  grid-template-columns: auto 1.5fr 1fr 1fr 1fr 2rem;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem;
  min-width: 38rem;
}

.eventListItem p {
  margin: 0;
  font-size: 12pt;
}

.emptyState {
  text-align: center;
}

@media screen and (max-width: 44rem) {
  .balanceStats {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
