.container {
  display: block;
  max-width: 38rem;
  text-align: start;
  padding: 0 2rem;
}

.image {
  display: block;
  height: 12rem;
  margin: 1rem auto;
}

.title {
  margin: 2rem 0 1rem 0;
  text-align: center;
}

.content {
  margin-bottom: 2rem;
}

.container a {
  display: inline-block;
  cursor: pointer;
  margin: 0rem auto 2rem auto;
}

.contact {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 0 2rem 0;
}

.contactInfos{
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon {
  display: block;
  padding: 0.6rem;
  border-radius: 20rem;
  background-color: #e4e8ec;
}

.contactDetail {
  display: flex;
  text-align: start;
  flex-direction: column;
  line-height: 1.2;
}

.contactDetail a {
  display: block;
  color: #001e3c;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
}

.contactDetail a:hover {
  text-decoration: underline;
}

.contactDetail span {
  margin: 0;
  display: block;
  font-style: italic;
  font-weight: 300;
  color: #555;
}

@media screen and (max-width: 44rem) {
  .image {
    height: 6rem;
  }
  .title {
    font-size: 15pt;
  }
  .container {
    padding: 0 0rem;
  }

  .content {
    font-size: 11pt;
  }
  
}
